.pool-top-container-container {
  margin: 68px auto;
  width: 80%;
  padding: 0px 8px;
}
@media (max-width: 767px) {
  .pool-top-container-container {
    margin: 30px auto;
    width: 95%;
    padding: 0px 8px;
  }
}
