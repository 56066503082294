.swap-container {
  width: 80%;
  /* max-width: 720px; */
  margin: 40px auto;
  min-height: 900px;
}

.swap-top-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.swap-top-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.swap-top-div {
  color: #EFEFE4;
}

.swap-top-div p:first-child {
  font-size: 40px;
  font-weight: bold;
  margin: 0px 0px;
}

.swap-top-div p:last-child {
  font-size: 16px;
  margin: 10px 0px;

}

.swap-top-right {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;

}

.swap-top-right img {
  width: 30px;

}

.swap-chart-toggle {
  cursor: pointer;
}

.swap-chart-toggle:active {
  color: #68FF9B;
}

.swap-mid-left-chart {}

/* swap-mid-container */
/* swap-mid-container */
.swap-mid-container {
  width: 100%;
  margin: auto;
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  /* gap: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 30px;

  margin-top: 40px;
}

.swap-mid-left {
  width: 100%;
  max-width: 720px;
  height: 500px;

}

.swap-mid-ledt-top-card {
  width: 100%;
  height: 232px;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.swap-mid-left-top-card-child-left {
  width: 70%;
}

.swap-mid-left-top-card-child-left p:first-child {
  font-size: 40px;
  color: #0A090F;
  font-weight: bold;
  margin: 0px;
}

.swap-mid-left-top-card-child-left-sub {
  font-size: 18px;
  color: #0A090F;
  font-weight: bold;
  width: 70%;
}

.swap-mid-left-top-card-child-right {
  width: 30%;
}

.swap-mid-left-top-card-child-right img {
  width: 200px;
}

/* button? */
.notched-button {
  --notchSize: 15px;
  clip-path:
    polygon(10px 0, 100% 0, 100% 70%, calc(100% - 10px) 100%, 0 100%, 0 30%);
  border: none;
  font-size: 18px;
  padding: 16px 70px;
  font-family: "Righteous";
  font-weight: 200;
  background: #0A090F;
  cursor: pointer;
}

.notched-button span {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.notched-button:hover {
  background: gray;

}

/* background: rgba(239, 239, 228, 0.1); */
.switch-version-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  color: #EFEFE4;
  background: #282828;
  max-width: 720px;
  gap: 20px;
  padding: 0px 10px 0px 0px;
}

.switch-version-right {
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  background: transparent;

}

.switch-version-left {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  padding: 15px 0px;
  font-size: 18px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #000 !important;
}

.swap-bot-button-container {
  margin-top: 30px;
  max-width: 720px;

}

/* 
swap-bot-button-container
swap-bot-button-container
swap-bot-button-container */

.swap-mid-right-top {
  width: 100%;
  height: fit-content;
  border: 2px solid rgba(239, 239, 228, 0.2);
  background: rgba(239, 239, 228, 0.03);
  padding: 30px;
  color: #EFEFE4;
  position: relative;
  margin-top: 30px;
}

.overlay-card {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-card p {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.swap-mid-right-top-title p:first-child {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  width: fit-content;
  margin: 0px;

}

.swap-mid-right-top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.swap-mid-right-top-child-left-button {
  width: 35%;
}

.swap-mid-right-top-child-left-button button {
  padding: 0px 0px;
}

.swap-mid-right-top-child-left-button-ref {
  width: 5%;
}

.swap-mid-right-top-child-left-button-custome {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  text-align: center;
}

.swap-mid-right-top-title-last {
  color: #EFEFE4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 16px;
}

.swap-mid-right-top-title-last span {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.swap-mid-right-top-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 5px 0px;
}

.swap-mid-right-top-child-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.swap-mid-right-top-child-left img {
  height: 32px;
}

.swap-mid-right-mid {
  margin-top: 30px;
  width: 100%;
  height: fit-content;
  border: 2px solid rgba(239, 239, 228, 0.2);
  background: rgba(239, 239, 228, 0.03);
  padding: 30px;
  position: relative;
  color: #EFEFE4;
}

.swap-mid-right-top-child-left span {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 300;
}

.swap-mid-right-bot {
  margin-top: 30px;
  width: 100%;
  height: fit-content;
  border: 2px solid rgba(239, 239, 228, 0.2);
  background: rgba(239, 239, 228, 0.03);
  padding: 30px;
  position: relative;
  color: #EFEFE4;
}

.swap-referral-sub {
  font-size: 16px;
  margin: 0px;
}

.swap-referral-sub-link {
  font-size: 14px;
  color: rgba(239, 239, 228, 0.5);
}

.swap-input-ref {
  background: rgba(239, 239, 228, 0.03);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 20px;
  font-weight: bold;
  color: rgba(239, 239, 228, 0.5);
  font-size: 21px;
}

.border-bottom-ref {
  padding-top: 20px;
  border-bottom: 2px solid rgba(239, 239, 228, 0.05);
  ;
}

.ref-result {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: rgba(239, 239, 228, 0.5);
  padding-top: 20px;
}

.link-wraptext {
  overflow-wrap: break-word;
  font-size: 12px;
}

.invitees-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.invitees-div {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invitees-div p {
  margin: 5px;

}


.swap-banner-right {
  /* background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/swap/banner.png'); */
  background-size: cover;
  padding: 18px 33px;
  position: relative;
  width: 512px;
  display: none !important;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
  
}

.monotrading-rw img {
  width: 110px;
  height: 43px;
}

.monotrading-rw img.point-rw {
  width: 129px;
}

@media screen and (max-width: 560px) {
  .farmnow_bg.monotrading {
    position: absolute;
    top: 7px;
    right: 0;
    z-index: 2;
    scale: 0.9;
  }

  .monotrading-rw img {
    width: 93px;
  }

  .monotrading-rw img.point-rw {
    width: 128px;
  }

  .swap-banner-right .title {
    font-size: 20px !important;
  }
}

.farmnow_bg {
  position: relative;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.farmnow_bg:hover {
  scale: 1.05;
}

.farmnow_bg span {
  font-size: 10px;
  color: #0A090F;
  font-size: 10px;
  font-family: Righteous;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 10;
  position: relative;
  z-index: 10;
}

.farmnow_bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.monotrading-rw {
  display: flex;
}


@media screen and (max-width: 768px) {
  .swap-banner-right {
    width: 100%;
    background-size: cover;
    padding: 10px 15px;
  }
}

/* color? */
/* warning: #FFB800 */
/* main: #efefe4
color: rgba(239, 239, 228, 0.5); */
/* backgroi modal: #1c1b20 */



.swap-trend-container {
  width: 100%;
  display: none !important;
  align-items: center;
  justify-content: space-around;
  background: rgba(239, 239, 228, 0.1);
  position: relative;
  overflow: hidden;
  height: 50px;
  margin-top: 20px;
}

.trend-card {
  flex: 0 0 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 12px;
}

.trend-card.delay-1 {
  animation-delay: 0s;
}

.trend-card.delay-2 {
  animation-delay: 5s;
}

.trend-card.delay-3 {
  animation-delay: 10s;
}

.trend-card.delay-4 {
  animation-delay: 15s;
}

.chart-comming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  font-size: 32px;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes pause-animation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(0%);
  }
}

.trend-card:hover,
.slide-wrapper:hover {
  animation-play-state: paused;
}

.trend-card:hover {
  color: #68FF9B;

}


.slide-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
  animation: slide-in 30s linear infinite both running;
}


@media (max-width: 1296px) {

  .swap-container {
    width: 90%;
    min-height: 1000px;
  }

  .swap-mid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 40px;
  }

  .swap-mid-left {
    height: 450px;
    margin: 0px auto;
  }
}

@media (max-width: 767px) {
  .swap-mid-right-top-title {
    flex-direction: column;

  }

  .swap-top-div p:first-child {
    font-size: 22px;
  }

  .swap-top-div p:last-child {
    font-size: 12px;
  }

  .swap-top-container {
    align-items: flex-start;
    flex-direction: column;
  }

  .swap-mid-right-top {
    padding: 10px;
  }

  .swap-mid-right-top {
    height: fit-content;
  }

  .swap-mid-right-mid {
    height: fit-content;
  }

  .swap-mid-right-bot {
    height: fit-content;
  }

  .swap-mid-right-top-child {
    flex-direction: column;
    align-items: flex-start;
  }

  .swap-mid-right-top-child-left-button {
    width: 100%;
    text-align: center;
  }

  .swap-mid-right-top-child-left-button-ref {
    width: 100%;
    text-align: center;
  }

  .swap-mid-right-top-child-left-button-custome {
    width: 90%;
    gap: 15px;
    margin: 0px auto;
    justify-content: center;
    text-align: center;
  }

  .link-wraptext {
    max-width: 200px;
    overflow-wrap: break-word;
    font-size: 8px;
  }

  .invitees-div p {
    font-size: 10px;

  }

  .swap-mid-right-top-title-last {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  .refreshes-div {
    margin: 10px 0px 0px 0px;
  }


}