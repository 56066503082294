.slider-wrapper {
  font-size: 40px;
  color: #aaa;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slider_container div {
  height: 48px;
  /* padding: 2px 15px; */
  color: #ffffff;
  text-align: center;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.slider_container {
  height: 100px;
  overflow: hidden;

}


.slider_text_1 {
  animation: slide 5s linear infinite;
}

.slider_text_2 {}

.slider_text_3 {}


@keyframes slide {
  0% {
    margin-top: -300px;
  }

  5% {
    /* For stopping effect */
    margin-top: -200px;
  }

  33% {
    margin-top: -200px;
  }

  38% {
    /* For stopping effect */
    margin-top: -100px;
  }

  66% {
    margin-top: -100px;
  }

  71% {
    /* For stopping effect */
    margin-top: 0px;
  }

  100% {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .slider {
    height: 34px;
  }

  .slider_container {
    height: 50px;
    overflow: hidden;

  }

}