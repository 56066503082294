.launchpad-container-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.launchpad-container-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.launchpad-container {
  width: 80%;
  margin: 20px auto;
  margin-bottom: 56px;
  position: relative;
  z-index: 2;
}

.launchpad-breadcrum {
  display: flex;
  gap: 12px;
  align-items: center;
  list-style: none;
  padding: 0;
}

.launchpad-breadcrum li {
  color: #FFF;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  opacity: 0.5;
}

.launchpad-breadcrum li svg {
  width: 12px;
  height: 12px;
  stroke-width: 2px;
}

.launchpad-breadcrum li.active {
  opacity: 1;
}

.launchpad-header {
  width: 70%;
}

.launchpad-header-title {
  font-size: 40px;
  color: #efefe4;
  margin-top: 48px;
  margin-bottom: 24px;
}

.launchpad-header-des {
  font-size: 16px;
  color: #efefe4;
  opacity: 0.5;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
}

.launchpad-hero {
  margin-top: 50px;
  margin-bottom: 30px;
  max-height: 309px;
  min-height: 120px;
  position: relative;
}

.launchpad-hero img {
  max-height: 309px;
  min-height: 120px;
}

.launchpad-hero-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.launchpad-hero-content h3 {
  text-align: center;
  font-family: Righteous;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.launchpad-hero-content span {
  color: #EFEFE4;
  text-align: center;
  font-family: Righteous;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}


.launchpad-info {
  background: rgba(239, 239, 228, 0.1);
  width: 100%;
  margin: 30px auto 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  height: fit-content;
  padding: 25px 20px;
  text-align: center;
  clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px);
}

.launchpad-info-child {
  padding: 0px 60px;
  text-align: center;
  width: 100%;
}

.launchpad-info-child p:first-child {
  color: rgba(239, 239, 228, 0.50);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin: 5px;
}

.launchpad-info-child p:last-child {
  color: var(--Primary, #EFEFE4);
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  margin: 5px;

}

.launchpad-midder {
  border-left: 1px solid rgba(239, 239, 228, 0.12);
}

.launchpad-buy-title {
  margin-bottom: 56px;
}

.launchpad-buy-title .guideline {
  margin-bottom: 32px;
}

.launchpad-buy-title-title {
  font-family: Righteous;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin: 0;
}


.launchpad-buy-title-container {
  margin-top: 77px;
  display: grid;
  gap: 76px;
  align-items: center;
}

.launchpad-buy-title.claiming .launchpad-buy-title-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
}

.launchpad-buy-title.claiming .launchpad-buy-title-title{
  width: 100%;
  text-align: left;
  margin-bottom: 32px;
}

.launchpad-buy-title.claiming .launchpad-counter{
  width: 100%;
  display: flex;
  justify-content: center;
}

.launchpad-buy-title.claiming {
  margin-bottom: 32px;
}

.count-down-box-launchpad {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.count-down-box-launchpad-show {
  margin: 0px;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  text-align: left;
}

.launchpad-counter-claimTime {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.launchpad-counter-top-title {
  color: #FFB800;
  font-size: 16px;
  margin: 5px 0px;
  width: 180px;
  text-align: left;
}

.launchpad-counter-top-title-claimTime {
  color: #FFB800;
  font-size: 16px;
}

.launchpad-buy-container {
  display: grid;
  grid-template-columns: 35% 65%;
  justify-content: center;
  gap: 20px;
  height: fit-content;
}

.launchpad-buy-container-left {
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  border: 1px solid rgba(239, 239, 228, 0.2);
  height: fit-content;
  position: relative;
  padding: 32px 24px;
  /* min-height: 700px; */
  height: 100%;
}

.launchpad-buy-container-right {
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  border: 1px solid rgba(239, 239, 228, 0.2);
  height: fit-content;
  position: relative;
  padding: 32px 24px;
  height: 100%;
  /* min-height: 700px; */
}

.launchpad-guidelines {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px auto;
}

.guildelines-card {
  width: 100%;
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  border: 1px solid rgba(239, 239, 228, 0.2);
  height: fit-content;
  position: relative;
  padding: 24px;
  height: fit-content;
  min-height: 300px;
}

.guildelines-card-title {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  font-size: 18px;
}

.guildelines-card-title img {
  width: 44px;
  height: 44px;
}


.guildelines-card-body {
  display: grid;
  gap: 16px;
}

.guildelines-card-body p:first-child {
  color: #EFEFE4;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
  margin-top: 6px;
}

.guildelines-card-body p:last-child {
  color: rgba(239, 239, 228, 0.70);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
}

.guildelines-card-body p:last-child span {
  display: block;
  margin-top: 16px;
}

.guildelines-card-body p:last-child .separator {
  margin-top: 12px;
}

.guildelines-card-body span {
  font-size: 16px;
  color: #efefe4;
}

.launchpad-buy-container-right-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-left: 40px;
}

.launchpad-buy-container-right-title img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  border: 1px solid #efefe412;
}

.launchpad-buy-container-right-title p,
.launchpad-buy-container-right-title span {
  font-size: 36px;
  font-family: Righteous;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.launchpad-buy-container-right-title span {
  color: rgba(239, 239, 228, 0.3);
}

.launchpad-buy-container-right-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
}

.launchpad-buy-input {
  background: rgba(239, 239, 228, 0.1);
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.launchpad-buy-input-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.launchpad-buy-input-icons p {
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.launchpad-buy-input input {
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  text-align: right;
  color: #efefe4;
  text-align: right;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.launchpad-buy-input input::placeholder {
  color: rgba(239, 239, 228, 0.50);
  text-align: right;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.launchpad-max-bg-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.launchpad-max-bg {
  padding: 5px 8px;
  background: rgba(239, 239, 228, 0.1);
  cursor: pointer;
}

.launchpad-max-bg p {
  margin: 0px;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.launchpad-max-bg-container span {
  color: rgba(239, 239, 228, 0.50);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.launchpad-buy-info {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid rgba(239, 239, 228, 0.3);
}

.launchpad-buy-info.claiming {
  margin-top: 20px;
}

.launchpad-buy-info-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.launchpad-buy-info-child p:first-child {
  color: rgba(239, 239, 228, 0.3);
  margin: 5px 0px;
  color: var(--Primary, #EFEFE4);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.launchpad-buy-info-child p:last-child {
  color: #efefe4;
  margin: 5px 0px;
  text-align: right;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.launchpad-button {
  margin-top: 40px;
}

.launchpad-button button {
  color: #0A090F;
  text-align: center;
  font-family: Righteous;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
}

.launchpad-referral {
  margin-top: 40px;
  padding: 5px 15px;
  background: rgba(239, 239, 228, 0.05);
  height: fit-content;
}

.launchpad-referral-title {
  color: rgba(239, 239, 228, 0.50);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.launchpad-referral-link {
  background: rgba(239, 239, 228, 0.2);
  padding: 14px 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.launchpad-referral-link p {
  margin: 3px 0px;
  font-size: 8px;
  word-wrap: break-word;
  overflow-wrap: break-word;

}

.launchpad-referral-link span {
  color: #EFEFE4;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  overflow-wrap: anywhere;
}

.launchpad-invited {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 1px solid rgba(239, 239, 228, 0.3);
}

.launchpad-invited p:last-child {
  color: #EFEFE4;
  text-align: right;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.launchpad-copy {
  cursor: pointer;
  color: #68ffb7;
  position: relative;
  width: fit-content;
}

.launchpad-copy-text {
  position: absolute;
  top: -25px;
  right: -7px;
  color: red;
  font-size: 10px !important;
  padding: 5px;
  background: #0A090F;
  border-radius: 5px;
}

.icon-clicked {
  color: rgba(239, 239, 228, 0.3);
}

.launchpad-waring {
  font-size: 14px;
  color: rgba(239, 239, 228, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 80px;
}

.launchpad-waring p {
  margin: 0px;
  color: #EFEFE4;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.5;
}

.laucnpad-claim-reward {
  margin: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.claim-button-launchpad {
  text-align: center;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(239, 239, 228, 0.3);
}

.claim-button-launchpad-disabled {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  color: rgba(239, 239, 228, 0.3);
  text-align: center;
  font-family: Righteous;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
}

.roadmap_desktop {
  margin-top: 40px;
}

.roadmap_mobile {
  display: none;
}

.roadmap-steps {
  margin-top: 56px;
}

.roadmap-steps .ant-timeline-item-head {
  background: unset !important;
}

.roadmap-steps .ant-steps-item-description {
  max-width: fit-content !important;
  width: fit-content !important;
}

.roadmap-steps .ant-timeline-item-tail {
  width: 4px;
  background: #EFEFE4;
  opacity: 0.3;
  inset-inline-start: 3px !important;
}

.roadmap-steps .ant-timeline-item-content {
  padding-top: 11px;
  padding-left: 80px;
}

@media (max-width: 1550px) {
  .guildelines-card {
    min-height: 450px;
  }
  .launchpad-info-child .launchpad-info-child.launchpad-midder{
    padding: 0px 40px;
  }
  .launchpad-info-child p:first-child{
    font-size: 13px;
  }
  .launchpad-info-child p:last-child{
    font-size: 16px;
    min-width: 100px;
  }
}

@media (max-width: 1200px) {
  .launchpad-container {
    width: 95%;
    margin: 20px auto;
  }

  .launchpad-header {
    width: 100%;
  }

  .launchpad-midder {
    border: none;
  }

  .launchpad-info-child {
    padding: 0;
  }

  .launchpad-buy-container {
    grid-template-columns: 100%;
  }

  .launchpad-buy-container-left {
    width: 100%;
  }

  .launchpad-buy-container-right {
    width: 100%;
  }

  .guildelines-card-body p {
    margin-left: 0px;
  }

  .launchpad-buy-container-left,
  .launchpad-buy-container-right {
    min-height: unset;
  }

  .launchpad-referral-link span {
    font-size: 16px;
  }
}

@media (max-width: 992px) {

  .launchpad-guidelines {
    grid-template-columns: repeat(1, 1fr);

  }

  .guildelines-card {
    min-height: fit-content;
  }
}

@media (max-width: 768px) {
  .launchpad-breadcrum li {
    font-size: 12px;
  }

  .launchpad-header-title {
    font-size: 32px;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .launchpad-header-des {
    font-size: 14px;
  }

  .launchpad-hero-content h3 {
    font-size: 24px;
  }

  .launchpad-hero-content span {
    font-size: 16px;
  }

  .launchpad-buy-title {
    margin-bottom: 24px;
  }

  .launchpad-buy-title.claiming {
    margin-bottom: 12px;
  }

  .launchpad-buy-title-container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    gap: 20px;
  }

  .launchpad-buy-title-title {
    font-size: 32px;
  }

  .launchpad-buy-container-right-title {
    margin-left: 20px;
  }

  .launchpad-buy-container-right-title img {
    width: 32px;
    height: 32px;
  }

  .launchpad-buy-container-right-title p {
    font-size: 24px;
  }

  .launchpad-buy-container-right-title span {
    font-size: 24px
  }

  .launchpad-buy-container-right {
    height: fit-content;
    padding: 0;
  }

  .launchpad-waring {
    font-size: 10px;
  }

  .launchpad-info-child p:first-child {
    font-size: 12px;
  }

  .launchpad-info-child p:last-child {
    font-size: 15px;
  }

  .launchpad-referral-link {
    display: grid;
    gap: 8px;
  }

  .launchpad-referral-link svg {
    height: 16px;
  }

  .launchpad-referral-link span {
    font-size: 14px;
  }

  .roadmap_desktop {
    display: none;
  }

  .roadmap_mobile {
    display: block;
  }
}

@media (max-width: 550px) {
  .launchpad-info {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .roadmap-steps .ant-timeline-item-content {
    padding-left: 30px;
  }
}