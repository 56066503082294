@import url('https://fonts.googleapis.com/css?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css?family=Urbanist&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.walletconnect-modal__base {
  /* Your custom styles here */
  color: #efefe4;
  border-radius: 0px !important;
  background-color: #111015 !important;
}

.walletconnect-qrcode__text {
  color: #efefe4 !important;
  font-weight: 300 !important;
}

.walletconnect-modal__mobile__toggle a {
  color: #000;
}

.walletconnect-modal__mobile__toggle {
  border-radius: 0px !important;
}

.walletconnect-modal__mobile__toggle_selector {
  border-radius: 0px !important;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%) !important;

}

.walletconnect-search__input {
  border-radius: 0px !important;
}

.walletconnect-connect__button__text {
  color: #efefe4 !important;
  font-weight: 300 !important;
  font-size: 14px !important;

}

.walletconnect-qrcode__notification {
  border-radius: 0px !important;
}

.walletconnect-qrcode__base {
  background-color: transparent !important;
 }

.ant-notification-notice-wrapper {
  background-color: black !important;
  border: 1px solid rgba(220, 220, 220, 0.287);
}

.ant-notification-notice-message,
.ant-notification-notice-wrapper .ant-notification-notice-close-x svg {
  color: #EFEFE4 !important;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
}

.marquee-container {
  display: flex;
  /* Use flexbox layout */
  overflow: hidden;
  /* Hide overflowing content */
}

.marquee-content {
  display: flex;
  /* Use flexbox layout */
  animation: marquee 30s linear infinite running;
  /* Adjust duration and timing function as needed */
}

@keyframes marquee {
  from {
      transform: translateX(0);
      /* Start from the right edge */
  }

  to {
      transform: translateX(-50%);
      /* Move to the left edge */
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}