 .ant-steps-item-tail {
   background-color: #68FFFF !important;
   padding-top: 1px !important;
   padding-bottom: 1px !important;
   top: 16px !important;
   inset-inline-start: 17.5px !important;
 }

 .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
   display: flex;
   justify-content: center;
   align-items: center;
 }

 @media screen and (max-width: 1200px) {
   .ant-steps-item-container {
     display: flex;
     align-items: center;
     gap: 20px
   }

   .ant-steps-item-tail {
     top: 30% !important;
   }
 }