.musd-container {
  width: 80%;
  margin: 40px auto 0px auto;
}

.swap-top-container-musd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swap-top-left-musd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.swap-top-div-musd {
  color: #EFEFE4;
}

.swap-top-div-musd p:first-child {
  font-size: 40px;
  font-weight: bold;
  margin: 0px 0px;
}

.swap-top-div p:last-child {
  font-size: 16px;
  margin: 10px 0px;

}

.musd-swap-input-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.musd-swap-input-title-add {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
}

.musd-container-title {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.musd-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 80%;
  margin: 0px auto;
}

.chart-musd-bg {
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/bgswap.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
}

.musd-chart {
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  width: 60%;
  height: 638px;
  border: 2px solid rgba(239, 239, 228, 0.2);
  padding: 30px;
}

.musd-swap {
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  width: 40%;
  height: 638px;
  border: 2px solid rgba(239, 239, 228, 0.2);

}

.musd-supply-container {
  width: 80%;
  margin: 10px auto 0px auto;
}

.musd-supply {
  margin: 20px auto;
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  width: 100%;
  /* height: 400px; */
  height: fit-content;
  border: 2px solid rgba(239, 239, 228, 0.2);
  padding: 20px 30px;
}

.uncover-bg {
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images//musd/uncover.jpg');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 690px;
  margin-top: 20px;
}

.musd-uncover-container {

  width: 80%;
  margin: 0px auto;
}

.musd-chart-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.musd-chart-top-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.musd-chart-top-pair {
  color: #efefe4;
  font-size: 36px;
}

.musd-chart-top-pair p {
  margin: 5px 0px;
}

.musd-chart-top-increase {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  color: #68FF9B;
}

.musd-chart-mid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.musd-chart-mid-time p {
  color: #efefe4;
  opacity: 50%;
  font-size: 16px;
}

.musd-chart-mid-select {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.musd-chart-mid-select p {
  padding: 5px 15px;
  border: 1px solid rgba(239, 239, 228, 0.2);
  color: rgba(239, 239, 228, 0.2);
  cursor: pointer;
}

.musd-chart-mid-select p:hover, .musd-chart-mid-select p.actived {
  color: #EFEFE4;
  border-color: #EFEFE4;
}

.musd-chart-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.musd-swap-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.musd-swap-tab-p {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: rgba(239, 239, 228, 0.3);
  font-size: 18px;

}

.musd-tab-selected {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  color: #000;
}

.musd-swap-bot-container {
  padding: 0px 20px;
}

.musd-swap-input-title {
  font-size: 32px;
  color: #efefe4;
}

.musd-swap-input-usdb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #1c1b1f;
  margin-top: 20px;

}

.musd-swap-input-usdb-text {
  background: rgba(239, 239, 228, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #efefe4;
  font-size: 16px;
  width: 30%;
}

.musd-swap-input-usdb input {
  background: transparent;
  width: 70%;
  border: none;
  border-color: transparent;
  font-size: 20px;
  padding: 5px 20px;
  text-align: right;
  color: #efefe4;
}

.musd-swap-input-usdb input:focus,
.musd-swap-input-usdb input:active,
.musd-swap-input-usdb input:hover {
  border: none;
  outline: none;
}

.musd-swap-input-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.musd-swap-input-balance-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.musd-max-button {
  background: #1c1b1f;
  padding: 3px 7px;
  cursor: pointer;
}

.musd-max-button p {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0px;
  font-size: 14px;
}

.musd-input-balance p {
  font-size: 12px;
  color: rgba(239, 239, 228, 0.5);
}

.musd-input-balance span {
  font-size: 12px;
  color: #efefe4;
}

.musd-input-limit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.musd-input-limit p {
  font-size: 12px;
  color: rgba(239, 239, 228, 0.5);
}

.musd-input-limit span {
  font-size: 12px;
  color: #efefe4;
  margin-left: 3px;
}

.user-minting-cap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(239, 239, 228, 0.03);
  border-radius: 9px 0px 9px 0px;
  width: 100%;
}

.user-minting-cap-bar {
  height: 12px;
  background-color: #62E6D4;
  border-radius: 9px 0px 9px 0px;
}

.user-minting-cap-full {
  height: 12px;
  background-color: #FFEEDA;
  border-radius: 9px 0px 9px 0px;
  width: 100%;
}

.total-minting-cap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(239, 239, 228, 0.03);
  border-radius: 9px 0px 9px 0px;
  width: 100%;
}

.total-minting-cap-bar {
  height: 12px;
  background-color: #68FF9B;
  border-radius: 9px 0px 9px 0px;
}

.total-minting-cap-full {
  height: 12px;
  background-color: #FFEEDA;
  border-radius: 9px 0px 9px 0px;
  width: 100%;
}

.loadingbar-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.loadingbar-info-pecentage {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.musd-connectwallet {
  margin-top: 60px;
}

.musd-supply-top {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* align-items: center; */
}

.musd-supply-top-card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  gap: 20px;
}

.musd-supply-top-card {
  background: #1c1b1f;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 0px 20px;
}

.musd-supply-top-card-small {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.musd-outlink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.musd-supply-chart {
  margin-top: 30px;
  height: 200px;
}

.musd-uncover-container {
  width: 80%;
  margin: 40px auto 0px auto;
}

.musd-uncover-title p {
  font-size: 56px;
  color: #efefe4;
  margin: 10px 0px;
}

.musd-uncover-title span {
  font-size: 56px;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.musd-uncover-subtitle {
  font-size: 18px;
  color: #efefe4;
  margin: 10px 0px;
}

.musd-uncover-card {
  background: #0A090F;
  width: 480px;
  height: 480px;
  padding: 20px 30px;
}

.musd-uncover-card-img-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.musd-uncover-card-img {
  width: 200px;

}

.musd-uncover-card-title {
  width: 100%;
}

.musd-uncover-card-title p {
  font-size: 32px;
  width: 60%;
  color: #efefe4;
}

.musd-uncover-card-subtitle p {
  font-size: 18px;
  width: 100%;
  color: rgba(239, 239, 228, 0.5);
}

.musd-uncover-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.swap-top-div-p-musd {
  margin-top: 30px;
}

.swap-top-container-img {
  text-align: right;
}

.musd-chart-top-increase {
  margin: 0px;
}

.musd-history-container {
  width: 80%;
  margin: 40px auto;
}

.musd-history-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.musd-history-top-title {
  font-size: 50px;
  color: #efefe4;
  margin: 10px;

}

.musd-history-top-sub-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
  color: rgba(239, 239, 228, 0.5);

}

.musd-history-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #efefe4;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(239, 239, 228, 0.2);

}

.musd-history-item-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 200px;
}

.musd-history-item-title p {
  color: #EFEFE4;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 143.75% */
}

.musd-history-paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.musd-history-paginate button {
  width: 15%;
}

.musd-history-paginate p {
  padding: 5px 15px;
  color: rgba(239, 239, 228, 0.5);
  cursor: pointer;

}

.history-musd-text-color {
  color: #68FF9B;
  text-align: right;

  /* Gilroy/Body-4/Semi */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  /* 143.75% */
}

.musd-history-item-created {
  text-align: left;
  color: #EFEFE4;
  text-align: right;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 143.75% */
}

.history-musd-text-color-withdraw {
  color: rgb(255, 30, 86);

}

.musd-history-top-filter span {
  cursor: pointer;
  color: rgba(239, 239, 228, 0.50);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 20px */
  text-transform: uppercase;
}

.musd-history-top-filter span:hover {
  color: #FFF;
}

@media (max-width: 1254px) {
  .musd-chart-top-pair {
    font-size: 32px;
  }

  .swap-top-container-img img {
    width: 60%;
  }

  .swap-top-div-p {
    margin-top: 10px;
  }

  .musd-chart-container {
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    gap: 30px;
  }

  .musd-chart {
    width: 70%;
    height: fit-content;
  }

  .musd-swap {
    height: fit-content;
    width: 70%;
    padding-bottom: 20px;

  }

  .musd-supply-top-card-container {
    flex-direction: column;
    width: 100%;
  }

  .musd-supply-top {
    flex-direction: column;
  }

  .musd-supply-container {
    width: 100%;
  }

  .musd-supply {
    height: fit-content;
    width: 70%;
    padding-bottom: 20px;
  }

  .musd-outlink {
    justify-content: flex-start;
    width: 100%;
  }

  .musd-uncover-card-container {
    flex-direction: column;
  }

  .uncover-bg {
    height: fit-content;
    background-image: none;
  }

  .musd-uncover-card {
    width: 100%;
    height: fit-content;
    padding: 10px 15px;
    background: rgba(239, 239, 228, 0.1);

  }

  .musd-uncover-card-title p {
    width: 100%;
    font-size: 24px;
  }

  .musd-uncover-card-subtitle p {
    width: 100%;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .musd-chart-axis-text{
    font-size: 10px;
  }
  .musd-chart-top-pair p {
    font-size: 14px;
  }

  .musd-chart-top {
    gap: 10px;
    flex-direction: column;
  }

  .musd-chart-top-increase {
    font-size: 12px;

  }

  .musd-chart-top-increase img {
    width: 16px;
  }

  .musd-chart-top-img {
    width: 30px;

  }

  .swap-top-container-img {
    display: none;
  }

  .swap-top-div-p {
    margin-top: 10px;
  }

  .musd-chart {
    width: 95%;
    padding: 15px 5px;
  }

  .musd-chart-top-left {
    gap: 10px;
  }

  .musd-swap {
    width: 90%;

  }

  .musd-chart-mid {
    flex-direction: column;
    margin-top: 10px;
  }

  .musd-chart-mid-time p {
    margin: 5px 0px;
  }

  .musd-chart-mid-select p {
    padding: 3px 7px;
    font-size: 14px;
  }

  .musd-chart-chart {
    margin-top: 20px;
  }

  .musd-supply {
    height: fit-content;
  }

  .musd-supply {
    height: fit-content;
    width: 90%;
    padding-bottom: 20px;
    padding: 20px 10px;
  }

  .musd-supply-top-card-small {
    font-size: 12px;
  }

  .musd-supply-chart {
    margin-top: 10px;
  }

  .musd-uncover-title p {
    font-size: 36px;
  }

  .musd-uncover-title span {
    font-size: 36px;
  }

  .musd-uncover-container {
    margin-top: 20px;
    width: 90%;
  }

  .musd-history-top {
    flex-direction: column;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .musd-history-top-filter{
    gap: 15px !important; 
  }

  .musd-history-top-title {
    font-family: Righteous;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }

  .musd-history-item {
    font-size: 12px;
  }

  .musd-history-container {
    width: 90%;
  }

  .musd-history-item-title {

    width: 120px;
  }

  .musd-history-item img {
    width: 16px;
  }

  .musd-history-top-sub-left {
    justify-content: space-between;
    width: 100%;
  }

  .musd-history-paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    gap: 10px;
  }

  .musd-history-paginate button {
    width: 100%;
  }
}