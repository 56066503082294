.switch-version-container-select {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
  color: #EFEFE4;
  gap: 5px;
  padding:0px;
  background: #282828;

}
.switch-version-right-select {
  font-size: 12px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  background: transparent;

}

.switch-version-left-select {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  padding: 10px 0px;
  font-size: 12px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #000 !important;
}