.deposit-card-container {
  background-color: #222126;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.deposit-card-container-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 30px 0px;
  color: rgba(239, 239, 228, 0.5);
}
.deposit-card-container-img p {
  margin: 5px;

}
.deposit-card-container p {
  font-size: 16px;
}
.deposit-card-container-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.deposit-card-info {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}
.deposit-card-container-title-span {
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
}
.deposite-card-detail p {
  margin: 5px;
  font-size: 14px;
  color: rgba(239, 239, 228, 0.5);

}