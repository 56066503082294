/* color? */
/* warning: #FFB800 */
/* main: #efefe4
color: rgba(239, 239, 228, 0.5); */
/* backgroi modal: #1c1b20 */

.pool-list-container {
  width: 80%;
  margin: 40px auto;
}
 

/* list card */
.pool-list-cover {
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/bgswap.svg'); 
  background-repeat: no-repeat;
  background-position: bottom; 
  width: 100%; 
}
.pools-list-card-container {
  display: grid;
  gap: 20px;
  margin-top: 30px;
 width: 80%;
 margin: 0px auto;
}

.pools-tvl-info {
  background: rgba(239, 239, 228, 0.1);
width: 100%;
margin: 30px auto 0px auto;
display: flex;
align-items: center;
justify-content: space-around;
gap: 20px;
height: fit-content;
padding: 10px 40px;
text-align: center;
}
.pools-tvl-info-child p:first-child {
  font-size: 14px;
  color: rgba(239, 239, 228, 0.5); 
  margin: 5px;
}
.pools-tvl-info-child p:last-child {
  font-size: 18px;
  color: #efefe4; 
  font-weight: bold;
  margin: 5px;

}
.middle-child {
  border-left: 1px solid rgba(239, 239, 228, 0.5);
  border-right : 1px solid rgba(239, 239, 228, 0.5);
 width: 30%;
}
.pools-list-card-container table {
  width:100%;
  margin: 0px auto;
  border-collapse: collapse;
}
/* table */
.pools-list-card-title {
  font-weight: 600;
  color : transparent;
  background:  linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip:  text;
  text-align: left;
}
.pools-list-card-td-1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin-left: 25px;
}
.pools-list-card-td-apr {
  color : transparent;
  background:  linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip:  text;
}
.pools-list-card-td-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 17px;
}
.pool-list-colored {
  background: rgba(239, 239, 228, 0.1); 

}
.pools-list-card-container table tbody tr {
  height: 65px;
}
@media (max-width: 1200px) {


  .pools-list-hide-mobile {
    display: none;
  }
  .pools-list-card-container {
    width: 95%;
  }
  .pools-list-card-container p{
   font-size: 12px;
  }
  .pools-list-card-container table tbody tr {
    height: 32px;
  }
}
@media (max-width: 767px) {
  .pools-tvl-info  {
    flex-direction: column;
  }
  .pool-list-container {
    width: 90%;
  }
  .middle-child {
    border: none;
    padding: 0px 0px;
  }
 
}