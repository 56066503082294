/* color? */
/* warning: #FFB800 */
/* main: #efefe4
color: rgba(239, 239, 228, 0.5); */
/* backgroi modal: #1c1b20 */
.button-rowfixed {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-pool-selector {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 35%;
  cursor: pointer;
  margin-bottom:20px ;
}
.tab-pool-selector-text {
  color: rgba(239, 239, 228, 0.5);
  display: flex;align-items: center;
  justify-content: center;
  padding: 5px;

}
.tab-pool-active {
  color:#efefe4;
}
.pool-position-card {
  margin: 30px 0px;

 display: grid;
 grid-template-columns: repeat(2,1fr);
  gap: 20px;
}
@media (max-width: 1279px) {
  .pool-position-card {
    grid-template-columns: repeat(1,1fr);
    }
    .tab-pool-selector {
      width: 100%;
    }
    
}
@media (max-width: 767px) {
  .tab-pool-selector {
    flex-direction: column;

  }
.button-rowfixed {
flex-direction: column;
width: 100%;
gap: 15px;
text-align: center;
}

}