/* color? */
/* warning: #FFB800 */
/* main: #efefe4
color: rgba(239, 239, 228, 0.5); */
/* backgroi modal: #111015 */
/* backgroi modal: #1c1b1f */
/* background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%); */

.pool-body-container-page {
  width: 100%;
}
.pool-body-mid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;
}
.pool-body-mid-left {
background-color: #111015;
border:2px solid rgba(239,239,228,0.2) ;
padding: 30px;
/* background-color: #fff; */

}
.pool-body-mid-right {
  background-color: #111015;
  /* background-color: #fff; */
  border:2px solid rgba(239,239,228,0.2) ;
  padding: 30px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
}
.pool-select-pair {
  background-color: #1c1b1f;
padding: 20px;
}
.pool-span-plus {
  color: #fefef4;
  font-weight: 100;
  position: absolute;
  bottom: 0px;
  left:50px
}
.add-pair {
  width: 100%;
}
.add-pair-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 1279px) {
  .pool-body-mid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 767px) {
  .pool-select-pair {
  padding: 10px;
  }
  .add-pair-div {
   flex-direction: column;
  }
  .add-pair-div-img{
   rotate: 90deg;
   padding: 10px auto;
  }
  .add-pair {
    width: 100%;
  }
.pool-body-mid-left {
padding: 10px;
}
.pool-body-mid-right {
padding: 10px;
display: inline;
}
.title-gap {
  margin: 20px auto;
}
}