/* color? */
/* warning: #FFB800 */
/* main: #efefe4
color: rgba(239, 239, 228, 0.5); */
/* backgroi modal: #1c1b20 */
.create-maestro-card-container {
  width: 80%;
  margin: 40px auto;
}

.alpha-top-container-container {
  margin-top: 68px;
  width: 80%;
  padding: 0px 8px;
}

.alpha-container {
  width: 80%;
  margin: 10px auto;
}

.page-title {
  color: #EFEFE4;
  font-family: Righteous;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin: 0;
  /* 50px */
}

.page-desc {
  margin: 0 !important;
}

.tab-pool-selector-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pool-status-filter-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pool-status-filter-box .status-filter-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  font-family: Righteous;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  background: linear-gradient(90deg, #68FFFF, #68FF9B);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.alpha-summary {
  clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px);
  background: #1d1d1c;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 100%;
  max-width: 1536px;
  height: 100px;
  padding: 28px 0;
  margin-top: 50px;
  margin-bottom: 40px;
}

.alpha-summary_item {
  display: flex;
  gap: 12px;
  align-items: center;
}

.alpha-summary_item img.desc_icon {
  width: 44px;
  height: 44px;
}

.summary_item_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.summary_item_content span {
  color: rgba(239, 239, 228, 0.50);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 17.5px */
}

.summary_item_content h5 {
  color: #EFEFE4;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 24px */
  text-transform: uppercase;
  margin: 0;
}

.alpha-summary-separator {
  width: 1px;
  height: 100%;
  background: rgba(239, 239, 228, 0.12);
  backdrop-filter: blur(4.800000190734863px);
}

.swap-top-div-maestro p:first-child {
  font-size: 40px;
  color: #efefe4;
}

.swap-top-div-maestro p {
  margin: 10px;
}

.swap-top-div-p-maestro {
  font-size: 18px;
  color: #efefe4;
}

.create-maestro-card {
  width: 50%;
  height: fit-content;
  /* background-color:#1c1b20; */
  margin: 0px auto;
}

.maestro-navigate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(239, 239, 228, 0.5);
  gap: 10px;
  margin-bottom: 20px;
}

.maestro-navigate p {
  cursor: pointer;
  margin: 0px;
}

.maestro-navigate p:hover {
  color: rgba(239, 239, 228, 0.9);

}

.maestro-card-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

}

.maestro-card-tab {
  background-color: #2d2c2f;
}

.maestro-card-select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px auto;
  width: 100%;
  text-align: center;
}

.maestro-card-select-add-icon {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(239, 239, 228, );
}

.maestro-card-select {
  background-color: #2d2c2f;
  width: 350px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.maestro-tab-p {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: rgba(239, 239, 228, 0.3);
  font-size: 16px;

}

.maestro-tab-selected {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  color: #000;
}

.maestro-card-select-rounded {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.maestro-card-select-add-icon p {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 42px;
  margin: 0px;
}

.maestro-detail-container {
  width: 80%;
  margin: 0px auto;
}

.mastro-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  padding-bottom: 20px;
}

.maestro-card-tab-container {
  width: 70%;
  text-align: left;
  justify-content: flex-start;
}

.maestro-search {
  background-color: rgba(239, 239, 228, 0.12);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
  padding: 5px;
}

.maestro-search input {
  background: transparent;
  border: none;
  color: #efefe4;
  padding: 0px 10px;
  height: 100%;
  width: 100%;
}

.maestro-search input:focus,
.maestro-search input:active,
.maestro-search input:hover {
  border: none;
  outline: none;
  box-shadow: none;
}


/* list card */
.maestro-cover {
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/bgswap.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
}

.alpha-list-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
  width: 80%;
  margin: 0px auto;
}

.alpha-setting {
  display: flex;
  gap: 8px;
}

.alpha-setting img {
  max-width: 24px;
  max-height: 24px;
  cursor: pointer;
}

.alpha-setting img:hover {
  scale: 1.05;
  transition: ease-in-out;
  transition-delay: 20ms;
}

.alpha-list-card {
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  border: 1px solid rgba(239, 239, 228, 0.2);
  width: 475px;
  /* height: fit-content; */
  position: relative;
  padding: 32px 20px;
}

.alpha-list-card-gift-box {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 8px;
}

.alpha-list-card-gift-box img {
  width: 32px;
  height: 23px;
}

.alpha-list-card-gift-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 23px;
}

.alpha-list-card-tag {
  position: absolute;
  top: 0;
  right: 0;
}

.alpha-list-card-detail {
  width: 100%;
}

.alpha-list-card-detail-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.alpha-list-card-detail-top p {
  font-size: 22px;
  margin: 10px;
}

.alpha-list-card-detail-top-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.alpha-list-card-detai--info {
  width: 100%;
  color: rgba(239, 239, 228, 0.5);
  text-align: right;
}

.alpha-list-card-detai--info-child {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 85%;
  height: 30px;
  margin-left: 60px;
}

.alpha-list-card-detai--info-child p {
  margin: 5px;
  font-size: 16px;
}

.alpha-list-card-detai--info-child p:last-child {
  margin: 5px 0;
}

.alpha-list-card-detai--info-child-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

/* detail */
.maestro-detail-cover {
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/bgswap.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
}

.maestro-detail-container {
  width: 80%;
  margin: 0px auto;
  height: fit-content;
}

.maestro-detail-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.maestro-detail-top-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #efefe4;
  gap: 20px;
}

.maestro-detail-top p {
  margin: 5px;
}

.maestro-detail-top-right-child {
  color: #efefe4;
  padding: 0px 20px;
  border-right: 1px solid rgba(239, 239, 228, 0.2);

}

.no-border {
  border: none;
}

.maestro-detail-top-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.maestro-detail-top-right-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.maestro-detail-top-right-child-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 0px 0px 20px;
}

.maestro-detail-top-right-child-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.alpha-detail-card-container-container {
  margin: 60px auto;
}

.alpha-detail-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.alpha-detail-card {
  background: rgba(239, 239, 228, 0.03);
  backdrop-filter: blur(4.8px);
  border: 1px solid rgba(239, 239, 228, 0.2);
  width: 600px;
  height: 210px;
  position: relative;
  padding: 20px 20px;
  color: rgba(239, 239, 228, 0.5);
}

.alpha-detail-card p {
  margin: 5px;
}

.alpha-detail-card-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alpha-detail-card-child p:last-child {
  color: #efefe4;
}

.alpha-detail-card-child-active {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
}

.alpha-detail-card-title {
  color: #efefe4;
  font-size: 18px;
  border-bottom: 1px solid rgba(239, 239, 228, 0.2);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alpha-detail-card-title-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 70%;
}

.alpha-detail-card-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.alpha-detail-card-card {
  background: rgba(239, 239, 228, 0.03);
  width: 100%;
  height: 105px;
  padding: 10px;
}

.alpha-detail-card-card-img {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .maestro-search img {
  width: 16px;
} */

.count-down-box {
  display: flex;
  align-items: center;
  justify-content: center;

}

@media (max-width: 1600px) {
  .alpha-summary {
    max-width: 1200px;
  }

  .alpha-list-card-container {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    justify-items: center
  }

  .alpha-list-card {
    width: 90%;
  }
}

@media (max-width: 1279px) {
  .page-title {
    font-size: 32px;
  }

  .alpha-summary {
    max-width: 80%;
    gap: 50px;
  }

  .alpha-list-card {
    width: 100%;
  }

  .alpha-detail-card-container-container {
    margin: 30px auto;
  }

  .maestro-search {
    width: 200px;
  }

  .alpha-list-card-container {
    grid-template-columns: repeat(1, 1fr);

  }

  .swap-top-div-maestro p:first-child {
    font-size: 18px;
    color: #efefe4;
  }

  .swap-top-div-p-maestro {
    font-size: 14px;
    color: #efefe4;
  }

  .create-maestro-card-container {
    width: 100%;
  }

  .create-maestro-card {
    width: 70%;
  }

  .alpha-detail-card-container {
    flex-direction: column;
  }

  .maestro-detail-top {
    flex-direction: column;
    justify-content: flex-start;

  }

  .maestro-detail-top-left {
    width: 100%;
    margin-bottom: 10px;
  }

  .maestro-detail-top-left {
    justify-content: center;
  }

  .maestro-detail-top-right {
    flex-direction: column;
    gap: 20px;
  }

  .maestro-detail-top-right-child-button {
    padding: 0px;
  }

  .maestro-detail-top-right-child {
    padding: 0px 10px;
  }
}

@media (max-width: 1080px) {
  .alpha-summary {
    display: grid;
    height: unset;
    gap: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .alpha-container {
    width: 90%;
  }

  .page-title {
    font-size: 24px;
  }

  .alpha-container .tab-pool-selector {
    flex-direction: row;
  }

  .alpha-container .swap-top-right {
    width: 100%;
    margin-top: 12px;
  }

  .maestro-search {
    width: 100%;
  }

  .alpha-summary {
    width: 100%;
    max-width: 90%;
  }

  .summary_item_content h5 {
    font-size: 18px;
  }

  .alpha-detail-card {
    width: 100%;
    height: fit-content;
  }

  .maestro-detail-container {
    width: 90%;
  }

  .create-maestro-card-container {
    width: 90%;
  }

  .maestro-card-select-container {
    flex-direction: column;

  }

  .create-maestro-card {
    width: 100%;
  }

  .alpha-list-card-container {
    width: 90%;
  }

  .alpha-detail-card-card-container {
    flex-direction: column;
  }

  .alpha-detail-card-card {
    width: 90%;
  }

  .alpha-detail-card-title {
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
  }

  .alpha-detail-card-title-right {
    width: 90%;
  }

  .alpha-list-card-detai--info-child {
    margin-left: 0px;
    margin: 0px auto;
  }

  .alpha-list-card-detail-top p {
    font-size: 16px;
  }

  .alpha-top-container-container {
    margin: 30px auto;
    width: 95%;
    padding: 0px 8px;
  }
}

@media (max-width: 550px) {
  .alpha-container .swap-top-left {
    align-items: flex-start !important;
  }
  .tab-pool-selector-wrapper{
    display: grid;
  }
  .count-down-box p{
    font-size: 14px;
  }
}