/* color? */
/* warning: #FFB800 */
/* main: #efefe4
color: rgba(239, 239, 228, 0.5); */
/* backgroi modal: #111015 */
/* backgroi modal: #1c1b1f */
/* background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%); */

.remove-container {
  width: 80%;
  margin: 40px auto;
  border:none
}
.remove-top {
  margin-bottom: 20px;
}
.remove-card-info {
  width: 50%;
  margin: 0px auto;
  background-color:#1c1b1f ;
  padding: 30px;
  margin-top: 30px;
border:2px solid rgba(239,239,228,0.2) ;

}
.remove-card-bottom {
  width: 50%;
}
@media (max-width: 1279px) {
  .remove-container {
    width: 90%;
  }
  .remove-card-info {
    width: 100%;
    padding: 15px;
  }
  .remove-card-bottom {
    width: 100%;
  }
}