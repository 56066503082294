.leaderboard-container {
  width: 80%;
  margin: 40px auto;
}

.paginate-table p {
  color: #EFEFE4;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
}

.paginate-table table {
  width: 100%;
  /* background:#1e1e1d ; */
  font-weight: 400;
  padding: 30px;
  border-collapse: collapse;
  /* min-width: 380px; */
}

.paginate-table {
  max-height: 710px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  position: relative;
}

.paginate-table table tbody tr td,
.paginate-table table thead tr th {
  text-align: left;
}


.paginate-table table tbody tr td:nth-child(1),
.paginate-table table thead tr th:nth-child(1) {
  width: 110px;
}

@media screen and (max-width: 1000px) {

  .paginate-table table tbody tr td:nth-child(1),
  .paginate-table table thead tr th:nth-child(1) {
    width: 80px;
  }
}

@media screen and (max-width: 600px) {
  .paginate-table table tbody tr td:nth-child(1),
  .paginate-table table thead tr th:nth-child(1) {
    width: 73px;
  }
}

@media screen and (max-width: 450px) {
  .paginate-table table tbody tr td:nth-child(1),
  .paginate-table table thead tr th:nth-child(1) {
    width: 60px;
  }
}



@media screen and (max-width: 390px) {

  .paginate-table table tbody tr td:nth-child(1),
  .paginate-table table thead tr th:nth-child(1) {
    width: 46px;
  }
}


.paginate-table table thead tr {
  background: #80808029;
}

.paginate-table table tbody tr {
  border-bottom: 1px solid #80808075
}

.paginate-button {
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginate-button span {
  color: #efefe4;
  padding: 0px 20px;
  font-weight: 400;

}

.paginate-button button {
  border: none;
  color: #000;
  padding: 10px 20px;
  cursor: pointer;
  width: 120px;
}

.leaderboard-colored {
  background: #282828;
}

.paginate-table table thead {
  position: sticky;
  opacity: 0.7;
}

#xp-leaderboard .paginate-table table td:nth-child(2),
#xp-leaderboard .paginate-table table th:nth-child(2) {
  width: 20%;
}

#xp-leaderboard .paginate-table table td:nth-child(3),
#xp-leaderboard .paginate-table table th:nth-child(3) {
  min-width: 65px;
  width: 30%;
}

.my-claimed-xp .paginate-table table td:nth-child(1),
.my-claimed-xp .paginate-table table th:nth-child(1) {
  width: 40px;
  min-width: 20px;
}

.my-claimed-xp .paginate-table table td,
.my-claimed-xp .paginate-table table th {
  min-width: 65px;
  width: 30%;
}

.my-claimed-xp .ant-pagination .ant-pagination-item {
  border-radius: 0 !important;
  border-color: #EFEFE4;
}

.my-claimed-xp .ant-pagination .ant-pagination-item a,
.my-claimed-xp span.ant-pagination-item-ellipsis, .my-claimed-xp .ant-pagination-item-link {
  color: #EFEFE4 !important;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.my-claimed-xp .ant-pagination .ant-pagination-item-active {
  background-color: #000;
  border-color: #68FFFF !important;
}

.my-claimed-xp .ant-pagination .ant-pagination-item-active a {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



@media (max-width: 767px) {
  .leaderboard-container {
    width: 90%;
    margin: 40px auto;
  }

  .paginate-table p {
    font-size: 10px;
  }


  .paginate-table {
    max-height: 500px;
  }

  .paginate-table table {
    padding: 10px;
  }

  .paginate-button {
    text-align: center;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .paginate-button button {

    width: 100%;
  }

  .paginate-button span {
    margin: 10px auto;

  }
}

.my-claimed-xp .ant-dropdown-menu {
  width: 150px;
  border: 1px solid #80808029;
  background-color: black;
  margin-top: 24px;
}

.my-claimed-xp .ant-dropdown-menu-title-content {
  color: #EFEFE4;
}
.my-claimed-xp .ant-dropdown-menu-item-active, .my-claimed-xp .ant-dropdown-menu-item-selected {
  background-color: #80808029 !important;
}