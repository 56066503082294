.btn-notched,
.btn-notched:focus,
.btn-notched:active {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: #68ffdf;
  padding: 0 11px;
  border-radius: 0;
  border: none;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.btn-notched:before,
.btn-notched:after {
  position: absolute;
  display: block;
  content: "";
  width: 23px;
  height: 23px;
  transform: rotate(42deg);
  z-index: 1;
}

.btn-notched:before {
  height: 22.2px;
  bottom: 3px;
  right: 2.5px;
  border-right: 1px solid #68ffdf;
}

.btn-notched:after {
  top: 3px;
  left: 2px;
  border-left: 1px solid #68ffdf;
}

.btn-notched .btn-text-notched,
.btn-notched:focus .btn-text-notched,
.btn-notched:active .btn-text-notched {
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.btn-notched .btn-text-notched {
  position: relative;
  display: block;
  line-height: 1;
  padding: 12px 25px;
  border-top: 1px solid #68ffdf;
  border-bottom: 1px solid #68ffdf;
  z-index: 3;
}

.btn-notched .btn-text-notched:before,
.btn-notched .btn-text-notched:after {
  display: block;
  position: absolute;
  content: "";
  width: 20px;
  height: calc(100% - 11.5px);
  z-index: 3;
}

.btn-notched .btn-text-notched:before {
  left: -11px;
  bottom: -1px;
  border-bottom: 1px solid #68ffdf;
  border-left: 1px solid #68ffdf;
}

.btn-notched .btn-text-notched:after {
  top: -1px;
  right: -11.2px;
  border-top: 1px solid #68ffdf;
  border-right: 1px solid #68ffdf;
}